import { Chip, Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import MuiButton from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import Dialog from '@material-ui/core/Dialog'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import {
  Add as IconContentAdd,
  Cancel as IconCancel,
  Edit as IconContentEdit,
} from '@material-ui/icons'

import type { ProfunctorState } from '@staltz/use-profunctor-state'
import type { Adress } from '@willig/types/api'
import React, { useState, useCallback, useMemo } from 'react'

import {
  SimpleShowLayout,
  TextField,
  useGetOne,
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  AutocompleteInput,
  ReferenceInput,
  required,
  TextInput,
  useUpdate,
} from 'react-admin'

import { useFormState, useForm } from 'react-final-form'
import GeoApiGouv from 'src/components/GeoApiGouv'
import { TypesenseInput } from 'src/components/Typesense/TypesenseInput'

import type { isProState } from '../interventionForm'

import AdresseQuickPreviewButton from './AdresseQuickPreviewButton'
import { InterventionHistoryModal } from './InterventionHistoryModal'

const spySubscription = { values: true }

type AdresseReferenceInputProps = {
  filterToQuery: (searchText: string) => { rue: string }
  onChange?: () => void
  target: 'adresse_id' | 'facture_adresse_id'
  inputLabel: string
  source: string
  reference: string
  validate: (value: any, values: any) => string | { message: string; args: any }
  perPage: number
  emptyAdresseInput: string[]
  stateProf?: ProfunctorState<isProState>
  searchQuery?: string
}

const AdresseReferenceInput = (props: AdresseReferenceInputProps) => {
  const { emptyAdresseInput, target, inputLabel, stateProf, searchQuery } =
    props
  const [version, setVersion] = useState(0)
  const classes = useStyles()
  const { values } = useFormState({ subscription: spySubscription })
  const handleChange = useCallback(() => setVersion(version + 1), [version])
  const { data } = useGetOne<Adress>('Adress', values[target], {
    enabled: Boolean(values[target]),
  })

  const isProAdress = data?.pro_id !== null
  useMemo(() => {
    stateProf?.setState((prev: any) => {
      return { ...prev, isPro: isProAdress }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProAdress, data])

  const [modalState, toggleModal] = useState<boolean>(false)
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <p>{inputLabel}</p>
        <AdresseButton onChange={handleChange} target={target} />
      </div>
      <div>
        <TypesenseInput
          data={data}
          source={target}
          indexName="adresses"
          inputLabel="Rechercher une adresse"
          emptyInputArray={emptyAdresseInput}
          searchQuery={searchQuery}
        />
      </div>

      {data && (
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.cardContent}>
            <Grid container>
              <Grid xs={11}>
                <SimpleShowLayout record={data}>
                  <TextField source="rue" />
                  <TextField source="code_postal" />
                  <TextField source="ville" />
                  <TextField source="cote" label="Intitulé d'adresse" />
                  <TextField source="etage" />
                  <TextField source="commentaire" />
                </SimpleShowLayout>
              </Grid>
              <Grid xs={1}>
                {stateProf?.state.isPro && (
                  <Chip
                    label="Pro"
                    color="secondary"
                    style={{ marginTop: '24px' }}
                  />
                )}
              </Grid>
            </Grid>
            <Box display="flex" flexDirection="column" gridGap={8}>
              <div className={classes.cardFooter}>
                {!!data && (
                  <AdresseButton
                    isEdit
                    target={target}
                    onChange={handleChange}
                    data={data}
                  />
                )}
                {!!data && <AdresseQuickPreviewButton data={data} />}
              </div>
              {!!data && (
                <>
                  <MuiButton
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => toggleModal(true)}
                  >
                    {"Ouvrir modal de l'historique"}
                  </MuiButton>
                  <InterventionHistoryModal
                    adresse_id={data.id}
                    toggleModal={toggleModal}
                    modalState={modalState}
                  />
                </>
              )}
              {!!data && (
                <MuiButton
                  color="primary"
                  variant="contained"
                  target="_blank"
                  href={`/#/Adress/${data.id}`}
                >
                  Ouvrir page adresse
                </MuiButton>
              )}
            </Box>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

export default AdresseReferenceInput

function AdresseButton(props: {
  onChange: any
  target: 'adresse_id' | 'facture_adresse_id'
  data?: Adress
  isEdit?: boolean
}) {
  const { onChange, target, data, isEdit = false } = props
  const [showDialog, setShowDialog] = useState(false)
  const [update, { loading: loadingUpdate }] = useUpdate('Adress', data?.id)
  const [createAdresse, { loading: loadingCreate }] = useCreate('Adress')
  const classes = useStyles()
  const notify = useNotify()
  const form = useForm()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmitCreate = async (values: any) => {
    createAdresse(
      { payload: { data: values } },
      {
        onSuccess: async (response: { data: any }) => {
          notify("Création de l'adresse effectuée", 'success')
          setShowDialog(false)
          form.change(target, response.data.id)
          onChange()
        },
        onFailure: (errorValue: { error: any }) => {
          notify(errorValue.error.message, 'error')
        },
      },
    )
  }
  const handleSubmitUpdate = async (values: any) => {
    if (data) {
      update(
        { payload: { data: values, id: data.id } },
        {
          onSuccess: (response) => {
            notify("Mise à jour de l'adresse effectuée", 'success')
            setShowDialog(false)
            form.change(target, response.data.id)
            onChange()
          },
          onFailure: (errorValue) => {
            notify(errorValue.error.message, 'error')
          },
        },
      )
    } else {
      notify('aucune adresse selectionée')
    }
  }

  const newAdress = useForm<Adress>()

  return (
    <>
      {isEdit ? (
        <Button onClick={handleClick} label="Modifier">
          <IconContentEdit />
        </Button>
      ) : (
        <Button onClick={handleClick} label="Nouveau">
          <IconContentAdd />
        </Button>
      )}

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={`${isEdit ? 'Modifier' : 'Créer'} une nouvelle adresse`}
      >
        <DialogTitle>{`${
          isEdit ? 'Modifier' : 'Créer'
        } une nouvelle adresse`}</DialogTitle>
        <FormWithRedirect
          initialValues={isEdit && data}
          record={data ?? newAdress}
          resource="Adress"
          save={isEdit ? handleSubmitUpdate : handleSubmitCreate}
          render={(renderProps) => {
            const { handleSubmitWithRedirect, saving } = renderProps
            return (
              <>
                <DialogContent className={classes.dialogContent}>
                  <fieldset>
                    <legend>Adresse</legend>
                    <Box>
                      <GeoApiGouv source="rue" label="Rue" />
                    </Box>
                    <Box display="flex" className={classes.inputGap}>
                      <TextInput fullWidth source="code_postal" />
                      <TextInput fullWidth source="ville" />
                    </Box>
                    <Box display="flex" className={classes.inputGap}>
                      <ReferenceInput
                        fullWidth
                        label="Zone"
                        source="zone"
                        reference="Zone"
                        filterToQuery={(searchText) => {
                          return { label: searchText }
                        }}
                        validate={[required()]}
                      >
                        <AutocompleteInput optionText="label" />
                      </ReferenceInput>
                    </Box>
                  </fieldset>
                  <fieldset>
                    <legend>Informations Complémentaires</legend>
                    <Box>
                      <TextInput fullWidth source="etage" />
                      <TextInput
                        fullWidth
                        source="cote"
                        label="Intitulé d'adresse"
                      />
                      <TextInput fullWidth source="ancienne_ville" />
                      <TextInput multiline fullWidth source="commentaire" />
                    </Box>
                  </fieldset>
                </DialogContent>
                <DialogActions>
                  <Button
                    label="Annuler"
                    onClick={handleCloseClick}
                    disabled={isEdit ? loadingUpdate : loadingCreate}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    label={`${isEdit ? 'Modifier' : 'Créer'} l'adresse`}
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    saving={saving}
                    disabled={isEdit ? loadingUpdate : loadingCreate}
                  />
                </DialogActions>
              </>
            )
          }}
        />
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    card: {
      flexGrow: 1,
    },
    cardContent: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogContent: {
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },

    inputGap: {
      gap: theme.spacing(2),
    },
    cardFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 'auto',
    },

    wrapper: { display: 'flex', flexDirection: 'column' },
    header: { display: 'flex', justifyContent: 'space-between' },
  }
})
