import { TableCell, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import type { PropsWithChildren } from 'react'

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: theme.spacing(0),
    },
  }),
)

export function useTableHeader(isPro: Boolean) {
  if (isPro) {
    return (
      <>
        <TableHeader>N° Facture</TableHeader>
        <TableHeader>Adresse d'intervention</TableHeader>
        <TableHeader>date d'intervention</TableHeader>
        <TableHeader>date d'échéance</TableHeader>
        <TableHeader>Prix (HT)</TableHeader>
        <TableHeader>Prix (TTC)</TableHeader>
        <TableHeader></TableHeader>
      </>
    )
  }
  return (
    <>
      <TableHeader>Payeur</TableHeader>
      <TableHeader>N° Facture</TableHeader>
      <TableHeader>Adresse d'intervention</TableHeader>
      <TableHeader>date d'intervention</TableHeader>
      <TableHeader>Ramoneur</TableHeader>
      <TableHeader>Paiement</TableHeader>
      <TableHeader>Prix (HT)</TableHeader>
      <TableHeader>Prix (TTC)</TableHeader>
      <TableHeader></TableHeader>
    </>
  )
}

function TableHeader(props: PropsWithChildren<{}>) {
  const { header } = useStyles()
  return (
    <TableCell className={header}>
      <Typography variant="h6">{props.children}</Typography>
    </TableCell>
  )
}
