export interface Adress {
  id: string
  zoho_id?: string
  rue: string
  code_postal: string
  ville: string
  etage?: string
  cote?: string
  lat?: number // double
  lon?: number // double
  ancienne_ville?: string
  zone: number
  secteur?: number
  commentaire?: string
  default_contact?: string
  pro_id?: string
  slug: string
  next_intervention_date?: string // date-time
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export interface Anomalie {
  id: number
  code_court: string
  code_long: string
  description?: string
  conseils?: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface Appareil {
  id: number
  code: string
  label: string
  tampon?: boolean
  non_concerne: boolean
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface Catalog {
  id: number
  type: EnumCatalogType
  mode: EnumCatalogMode
  name: string
  appareil_id?: string
  gas?: EnumCatalogGas
  price?: string
  change_dt?: string // date-time
  create_dt?: string // date-time
  deleted?: boolean
  collective: boolean
  extra?: EnumCatalogExtra
}

export interface Combustible {
  id: number
  code: string
  label: string
  in_shortlist: boolean
  family: EnumCombustibleFamily
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface Complexite {
  id: number
  label?: string
  type_prix: EnumComplexiteTypePrix
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface Contact {
  id: string
  zoho_id?: string
  prenom?: string
  nom?: string
  mobile?: string
  fixe?: string
  email?: string
  accept_sms: boolean
  accept_email?: boolean
  type: EnumContactType
  raison_sociale?: string
  pro_id?: string
  commentaire?: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export interface ContactsAdress {
  id: number
  contact_id: string
  adresse_id: string
  type: EnumContactsAdressType
  is_payor: boolean
  ref_marche?: string
  commentaire?: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export interface Contract {
  id: string
  num_quote?: string
  num_order?: string
  ref_billing?: string
  prerequisites?: string
  end_date: string // date-time
  devis: string
  total_price?: number // double
  contact_id?: string
  adresse_id: string
  facture_contact_id: string
  pro_id: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export interface CrmResponse {
  id: string
  adresse_id: string
  crm_status: EnumCrmResponseCrmStatus
  crm_date?: string // date-time
  sweep_id?: string
}

export interface Emplacement {
  id: number
  code: string
  label: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface File {
  id: string
  path: string
  mime: string
  type: EnumFileType
  change_dt?: string // date-time
  deleted?: boolean
}

export interface FullAddress {
  last_intervention_date?: string // date-time
  last_intervention_status?: number
  last_intervention_id?: string
  has_anomalies?: boolean
  anomalies?: string
  last_intervention_status_label?: string
  next_intervention_date?: string // date-time
  id: string
  rue?: string
  code_postal?: string
  ville?: string
  etage?: string
  cote?: string
  zone?: number
  deleted?: boolean
  commentaire?: string
  pro_id?: string
  work_interval?: number
}

export interface FullAddressesAnomaly {
  adresse_id: string
  rue: string
  ville: string
  code_postal: string
  pro_id?: string
  anomalies?: string
  rdv_date: string // date-time
  rdv_time?: string
  inter_id: string
  name?: string
}

export interface FullCatalog {
  id: number
  type: EnumFullCatalogType
  mode: EnumFullCatalogMode
  name: string
  appareil_id?: string
  gas?: EnumFullCatalogGas
  price?: string
  change_dt?: string // date-time
  create_dt?: string // date-time
  deleted?: boolean
  collective: boolean
  extra?: EnumFullCatalogExtra
  price_simple?: number // double
  price_medium?: number // double
  price_hard?: number // double
  complexite?: string
  type_prix: EnumFullCatalogTypePrix
}

export interface FullCatalogOnlyPrice {
  id: number
  type: EnumFullCatalogOnlyPriceType
  mode: EnumFullCatalogOnlyPriceMode
  name: string
  appareil_id?: string
  gas?: EnumFullCatalogOnlyPriceGas
  price?: string
  change_dt?: string // date-time
  create_dt?: string // date-time
  deleted?: boolean
  collective: boolean
  extra?: EnumFullCatalogOnlyPriceExtra
  price_simple?: number // double
  price_medium?: number // double
  price_hard?: number // double
}

export interface FullContactAdresse {
  id: number
  type_relation: EnumFullContactAdresseTypeRelation
  ref_marche?: string
  contact_adresse_commentaire?: string
  contact_id: string
  is_payor: boolean
  contact_sellsy?: string
  prenom?: string
  nom?: string
  mobile?: string
  fixe?: string
  email?: string
  accept_sms?: boolean
  type_contact?: EnumFullContactAdresseTypeContact
  raison_sociale?: string
  contact_commentaire?: string
  deleted?: boolean
  adresse_id: string
  adresse_sellsy?: string
  rue?: string
  code_postal?: string
  ville?: string
  etage?: string
  cote?: string
  lat?: number // double
  lon?: number // double
  ancienne_ville?: string
  zone?: number
  secteur?: number
  adresse_commentaire?: string
  change_dt?: string // date-time
  create_dt?: string // date-time
}

export interface FullContract {
  pro_name: string
  TYPE: EnumFullContractTYPE
  pro_id: string
  contact_id?: string
  contact_type: EnumFullContractContactType
  facture_contact_id: string
  facture_adresse_id: string
  adresse_rue: string
  adresse_code_postal: string
  adresse_ville: string
  adresse_id: string
  next_intervention_date?: string // date-time
  id: string
  total_price?: number // double
  prerequisites?: string
  total_installations: number
  installations_todo?: number
}

export interface FullInstallation {
  id: string
  type: number
  complete: boolean
  json: string
  code_pro?: string
  pro_id?: string
  price?: number // double
  prix_conduit?: number // double
  prix_appareils?: number // double
  prix_tuyaux?: number // double
  next_intervention_date?: string // date-time
  work_interval?: number
  libelle?: string
  detail?: string
  libelle_anomalies?: string
  anomalies?: number
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
  adresse_id: string
  contact_id?: string
  status: number
  sweep_id: string
  intervention_id: string
}

export interface FullIntervention {
  id: string
  zoho_id?: string
  adresse_id: string
  contact_id?: string
  facture_contact_id?: string
  facture_adresse_id?: string
  contact_relation_type?: EnumFullInterventionContactRelationType
  contact_relation_comment?: string
  invoice_relation_type?: EnumFullInterventionInvoiceRelationType
  invoice_relation_comment?: string
  rdv_date: string // date-time
  rdv_time?: string
  infos?: string
  status: number
  fixed_slot: boolean
  duration: number
  need_invoice: boolean
  prerequisites?: string
  sweep_id: string
  contract_id?: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
  code?: string
  label?: string
  bg_color?: string
  text_color?: string
  int_rue?: string
  int_code_postal?: string
  int_ville?: string
  int_etage?: string
  int_cote?: string
  int_lat?: number // double
  int_lon?: number // double
  int_commentaire?: string
  pro_id?: string
  facture_rue?: string
  facture_code_postal?: string
  facture_ville?: string
  contact_prenom?: string
  contact_nom?: string
  contact_mobile?: string
  contact_fixe?: string
  contact_email?: string
  contact_accept_sms?: boolean
  contact_type?: EnumFullInterventionContactType
  contact_accept_email?: boolean
  contact_raison_sociale?: string
  contact_commentaire?: string
  contact_type_user?: EnumFullInterventionContactTypeUser
  facture_prenom?: string
  facture_nom?: string
  facture_mobile?: string
  facture_fixe?: string
  facture_email?: string
  facture_type?: EnumFullInterventionFactureType
  facture_raison_sociale?: string
  facture_commentaire?: string
  sweeper?: string
}

export interface FullInterventionsHistory {
  id: string
  rdv_date: string // date-time
  rdv_time?: string
  contact_id?: string
  facture_contact_id?: string
  adresse_id: string
  facture_adresse_id?: string
  infos?: string
  ramoneur_id?: string
  ramoneur_name?: string
  status_id?: number
  status_label?: string
}

export interface FullInterventionsWithoutTime {
  id: string
  zoho_id?: string
  adresse_id: string
  contact_id?: string
  facture_contact_id?: string
  facture_adresse_id?: string
  contact_relation_type?: EnumFullInterventionsWithoutTimeContactRelationType
  contact_relation_comment?: string
  invoice_relation_type?: EnumFullInterventionsWithoutTimeInvoiceRelationType
  invoice_relation_comment?: string
  rdv_date: string // date-time
  rdv_time?: string
  infos?: string
  status: number
  fixed_slot: boolean
  duration: number
  need_invoice: boolean
  prerequisites?: string
  sweep_id: string
  contract_id?: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
  code?: string
  label?: string
  bg_color?: string
  text_color?: string
  int_rue?: string
  int_code_postal?: string
  int_ville?: string
  int_etage?: string
  int_cote?: string
  int_lat?: number // double
  int_lon?: number // double
  int_commentaire?: string
  facture_rue?: string
  facture_code_postal?: string
  facture_ville?: string
  contact_prenom?: string
  contact_nom?: string
  contact_mobile?: string
  contact_fixe?: string
  contact_email?: string
  contact_accept_sms?: boolean
  contact_type?: EnumFullInterventionsWithoutTimeContactType
  contact_raison_sociale?: string
  contact_commentaire?: string
  contact_type_user?: EnumFullInterventionsWithoutTimeContactTypeUser
  facture_prenom?: string
  facture_nom?: string
  facture_mobile?: string
  facture_fixe?: string
  facture_email?: string
  facture_type?: EnumFullInterventionsWithoutTimeFactureType
  facture_raison_sociale?: string
  facture_commentaire?: string
  sweeper?: string
}

export interface FullInvoicesIntervention {
  id: string
  create_dt?: string // date-time
  payment?: string
  payment_type?: EnumFullInvoicesInterventionPaymentType
  invoice?: string
  devis?: string
  devis_complementaire?: string
  certificat?: string
  bon_intervention?: string
  work?: string
  pdf_id?: string
  photo_ids?: string
  int_date?: string // date-time
  int_time?: string
  intervention_id?: string
  intervention_infos?: string
  prix_total?: number // double
  contract_contact_id?: string
  contract_raison?: string
  contract_prenom?: string
  contract_nom?: string
  contract_zoho_id?: string
  contract_numero_commande?: string
  ramoneur?: string
  adresse_id?: string
  pro_id?: string
  int_rue?: string
  int_cp?: string
  int_ville?: string
  int_zoho_id?: string
  facture_adrese_id?: string
  facture_rue?: string
  facture_cp?: string
  facture_ville?: string
  facture_zoho_id?: string
  facture_raison_sociale?: string
  facture_contact_prenom?: string
  facture_contact_nom?: string
  facture_contact_id?: string
  facture_contact_zoho_id?: string
  contact_raison_sociale?: string
  contact_prenom?: string
  contact_nom?: string
  contact_id?: string
  contact_zoho_id?: string
}

export interface FullSpecialEvent {
  id: string
  rdv_date: string // date-time
  rdv_time?: string
  duration?: number
  description?: string
  sweep_id: string
  event_type_id: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
  label?: string
  bg_color?: string
  text_color?: string
}

export interface FullStat {
  y?: number
  mo?: string
  todo?: string
  type: string
}

export interface FullTobedone {
  id: string
  rue?: string
  code_postal?: string
  ville?: string
  etage?: string
  cote?: string
  lat?: number // double
  lon?: number // double
  zone?: number
  secteur?: number
  pro_id?: string
  deleted?: boolean
  next_intervention_date?: string // date-time
  current_year?: string
  year_minus_one?: string
  year_minus_two?: string
  contacts?: string
  rdv_en_cours?: string
  crm_status?: EnumFullTobedoneCrmStatus
  crm_date?: string // date-time
}

export interface FullTown {
  ville: string
  id: string
}

export interface FullZoneCity {
  zone: number
  cities?: string
}

export interface Hauteur {
  id: number
  label: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface Incident {
  id: string
  type: number
  description?: string
  files?: string
  intervention_id: string
  sweep_id: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export interface Installation {
  id: string
  type: number
  complete: boolean
  json: string
  code_pro?: string
  pro_id?: string
  price?: number // double
  prix_conduit?: number // double
  prix_appareils?: number // double
  prix_tuyaux?: number // double
  next_intervention_date?: string // date-time
  work_interval?: number
  libelle?: string
  detail?: string
  libelle_anomalies?: string
  anomalies?: number
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
  adresse_id: string
  contact_id?: string
}

export interface InterventionFile {
  id: string
  intervention_id: string
  file_id?: string
  payload?: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted?: boolean
}

export interface InterventionReport {
  id: string
  zoho_id?: string
  intervention_id: string
  sweep_id: string
  devis?: string
  invoice?: string
  work?: string
  to_be_done?: string
  anomalies?: string
  has_anomalies?: boolean
  devis_complementaire?: string
  payment?: string
  payment_type?: EnumInterventionReportPaymentType
  pdf_id?: string
  photo_ids?: string
  file_id?: string
  bon_id?: string
  duration?: number
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export interface Intervention {
  id: string
  zoho_id?: string
  adresse_id: string
  contact_id?: string
  facture_contact_id?: string
  facture_adresse_id?: string
  contact_relation_type?: EnumInterventionContactRelationType
  contact_relation_comment?: string
  invoice_relation_type?: EnumInterventionInvoiceRelationType
  invoice_relation_comment?: string
  rdv_date: string // date-time
  rdv_time?: string
  infos?: string
  status: number
  fixed_slot: boolean
  duration: number
  need_invoice: boolean
  prerequisites?: string
  sweep_id: string
  contract_id?: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export interface Invoice {
  id: string
  invoice_number?: string
  line_items: string
  recurring_invoice_id?: string
  email?: string
  custom_field?: string
  search_text?: string
  sort_column?: EnumInvoiceSortColumn
  customer_name?: string
  customer_id: string
  status?: EnumInvoiceStatus
  reference_number?: string
  date: string // date-time
  due_date?: string // date-time
  due_days?: string
  currency_id?: string
  currency_code?: string
  total?: number // float
  balance?: number // float
  created_time?: string // date-time
  is_emailed?: boolean
  reminders_sent?: number
  payment_expected_date?: string // date-time
  last_payment_date?: string // date-time
}

export interface Log {
  id: string
  path?: string
  post_body?: string
  nx_error_code?: number
  status_code?: number
  date?: string // date-time
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export interface LogDeletion {
  id: string
  user_id?: string
  item_id?: string
  file_id?: string
  model?: string
  is_from_import_pro: boolean
  request_from?: string
  create_dt: string // date-time
  change_dt: string // date-time
}

export interface Marque {
  id: number
  label: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface MateriauConduit {
  id: number
  label?: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface Price {
  id: string
  price_simple?: number // double
  price_medium?: number // double
  price_hard?: number // double
  change_dt?: string // date-time
  create_dt?: string // date-time
  deleted?: boolean
}

export interface Produit {
  id: number
  code: string
  label: string
  pour_installation?: boolean
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface Pro {
  id: string
  name: string
  end_date?: string // date-time
  default_contact_id: string
  default_address_id: string
  type: EnumProType
  tva: number
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export interface ProsFile {
  id: string
  pro_id: string
  file_id: string
  create_dt?: string // date-time
  deleted?: boolean
}

export interface RamoneDepui {
  id: number
  label: string
  roof: boolean
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface Secteur {
  id: number
  code: string
  label: string
  zone_id: number
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface Section {
  id: number
  label: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface SpecialEvent {
  id: string
  rdv_date: string // date-time
  rdv_time?: string
  duration?: number
  description?: string
  sweep_id: string
  event_type_id: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export interface SpecialEventsType {
  id: string
  label: string
  bg_color?: string
  text_color?: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export interface StatusIntervention {
  id: number
  code: string
  label: string
  bg_color?: string
  text_color?: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface TmpJb {
  pdf?: string
  change_dt?: string // date-time
}

export interface TypeClient {
  id: number
  code: string
  label: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface TypeEncaissement {
  id: number
  code: string
  label: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface TypeIncident {
  id: number
  code: string
  label: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface TypeInstallation {
  id: number
  label: string
  code: string
  default: boolean
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface TypeTva {
  id: number
  code: string
  label: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export interface UserExtranet {
  id: string
  lastname?: string
  firstname?: string
  email: string
  password: string
  accept_alert_anomaly?: boolean
  pro_ids?: string
  is_pro?: boolean
  last_login?: string // date-time
  adresse_id?: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  access_level: number
  deleted?: boolean
}

export interface User {
  id: string
  login: string
  password: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
  access_level: number
}

export interface Zone {
  id: number
  code: string
  label: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
}

export enum EnumCatalogType {
  'Fumée' = 'Fumée',
  'Air' = 'Air',
}

export enum EnumCatalogMode {
  'Prestation' = 'Prestation',
  'Divers' = 'Divers',
  'Pack 1' = 'Pack 1',
  'Pack 2' = 'Pack 2',
  'Appareil seul' = 'Appareil seul',
  'Tuyau seul' = 'Tuyau seul',
  'Conduit seul' = 'Conduit seul',
}

export enum EnumCatalogGas {
  'solide' = 'solide',
  'liquide' = 'liquide',
  'gazeux' = 'gazeux',
  'pellet' = 'pellet',
}

export enum EnumCatalogExtra {
  'tampon' = 'tampon',
  'toit' = 'toit',
  'bistre' = 'bistre',
}

export enum EnumCombustibleFamily {
  'solide' = 'solide',
  'liquide' = 'liquide',
  'gazeux' = 'gazeux',
  'pellet' = 'pellet',
}

export enum EnumComplexiteTypePrix {
  'price_simple' = 'price_simple',
  'price_medium' = 'price_medium',
  'price_hard' = 'price_hard',
}

export enum EnumContactType {
  'particulier' = 'particulier',
  'professionnel' = 'professionnel',
  'collectivité' = 'collectivité',
  'syndic' = 'syndic',
  'gestionnaire' = 'gestionnaire',
  'contact' = 'contact',
}

export enum EnumContactsAdressType {
  'proprietaire' = 'proprietaire',
  'syndic' = 'syndic',
  'marche' = 'marche',
  'contact' = 'contact',
  'locataire' = 'locataire',
  'payeur' = 'payeur',
}

export enum EnumCrmResponseCrmStatus {
  'contacte' = 'contacte',
  'messagerie' = 'messagerie',
  'sans reponse' = 'sans reponse',
  'a contacter' = 'a contacter',
  'refuse' = 'refuse',
  'refus definitif' = 'refus definitif',
}

export enum EnumFileType {
  'devis' = 'devis',
  'facture' = 'facture',
  'certificat' = 'certificat',
  'bon' = 'bon',
  'cheque' = 'cheque',
  'photo' = 'photo',
  'import' = 'import',
}

export enum EnumFullCatalogType {
  'Fumée' = 'Fumée',
  'Air' = 'Air',
}

export enum EnumFullCatalogMode {
  'Prestation' = 'Prestation',
  'Divers' = 'Divers',
  'Pack 1' = 'Pack 1',
  'Pack 2' = 'Pack 2',
  'Appareil seul' = 'Appareil seul',
  'Tuyau seul' = 'Tuyau seul',
  'Conduit seul' = 'Conduit seul',
}

export enum EnumFullCatalogGas {
  'solide' = 'solide',
  'liquide' = 'liquide',
  'gazeux' = 'gazeux',
  'pellet' = 'pellet',
}

export enum EnumFullCatalogExtra {
  'tampon' = 'tampon',
  'toit' = 'toit',
  'bistre' = 'bistre',
}

export enum EnumFullCatalogTypePrix {
  'price_simple' = 'price_simple',
  'price_medium' = 'price_medium',
  'price_hard' = 'price_hard',
}

export enum EnumFullCatalogOnlyPriceType {
  'Fumée' = 'Fumée',
  'Air' = 'Air',
}

export enum EnumFullCatalogOnlyPriceMode {
  'Prestation' = 'Prestation',
  'Divers' = 'Divers',
  'Pack 1' = 'Pack 1',
  'Pack 2' = 'Pack 2',
  'Appareil seul' = 'Appareil seul',
  'Tuyau seul' = 'Tuyau seul',
  'Conduit seul' = 'Conduit seul',
}

export enum EnumFullCatalogOnlyPriceGas {
  'solide' = 'solide',
  'liquide' = 'liquide',
  'gazeux' = 'gazeux',
  'pellet' = 'pellet',
}

export enum EnumFullCatalogOnlyPriceExtra {
  'tampon' = 'tampon',
  'toit' = 'toit',
  'bistre' = 'bistre',
}

export enum EnumFullContactAdresseTypeRelation {
  'proprietaire' = 'proprietaire',
  'syndic' = 'syndic',
  'marche' = 'marche',
  'contact' = 'contact',
  'locataire' = 'locataire',
  'payeur' = 'payeur',
}

export enum EnumFullContactAdresseTypeContact {
  'particulier' = 'particulier',
  'professionnel' = 'professionnel',
  'collectivité' = 'collectivité',
  'syndic' = 'syndic',
  'gestionnaire' = 'gestionnaire',
  'contact' = 'contact',
}

export enum EnumFullContractTYPE {
  'syndic' = 'syndic',
  'marche' = 'marche',
}

export enum EnumFullContractContactType {
  'proprietaire' = 'proprietaire',
  'syndic' = 'syndic',
  'marche' = 'marche',
  'contact' = 'contact',
  'locataire' = 'locataire',
  'payeur' = 'payeur',
}

export enum EnumFullInterventionContactRelationType {
  'locataire' = 'locataire',
  'proprietaire' = 'proprietaire',
  'syndic' = 'syndic',
  'marche' = 'marche',
  'contact' = 'contact',
}

export enum EnumFullInterventionInvoiceRelationType {
  'locataire' = 'locataire',
  'proprietaire' = 'proprietaire',
  'syndic' = 'syndic',
  'marche' = 'marche',
  'contact' = 'contact',
}

export enum EnumFullInterventionContactType {
  'particulier' = 'particulier',
  'professionnel' = 'professionnel',
  'collectivité' = 'collectivité',
  'syndic' = 'syndic',
  'gestionnaire' = 'gestionnaire',
  'contact' = 'contact',
}

export enum EnumFullInterventionContactTypeUser {
  'proprietaire' = 'proprietaire',
  'syndic' = 'syndic',
  'marche' = 'marche',
  'contact' = 'contact',
  'locataire' = 'locataire',
  'payeur' = 'payeur',
}

export enum EnumFullInterventionFactureType {
  'particulier' = 'particulier',
  'professionnel' = 'professionnel',
  'collectivité' = 'collectivité',
  'syndic' = 'syndic',
  'gestionnaire' = 'gestionnaire',
  'contact' = 'contact',
}

export enum EnumFullInterventionsWithoutTimeContactRelationType {
  'locataire' = 'locataire',
  'proprietaire' = 'proprietaire',
  'syndic' = 'syndic',
  'marche' = 'marche',
  'contact' = 'contact',
}

export enum EnumFullInterventionsWithoutTimeInvoiceRelationType {
  'locataire' = 'locataire',
  'proprietaire' = 'proprietaire',
  'syndic' = 'syndic',
  'marche' = 'marche',
  'contact' = 'contact',
}

export enum EnumFullInterventionsWithoutTimeContactType {
  'particulier' = 'particulier',
  'professionnel' = 'professionnel',
  'collectivité' = 'collectivité',
  'syndic' = 'syndic',
  'gestionnaire' = 'gestionnaire',
  'contact' = 'contact',
}

export enum EnumFullInterventionsWithoutTimeContactTypeUser {
  'proprietaire' = 'proprietaire',
  'syndic' = 'syndic',
  'marche' = 'marche',
  'contact' = 'contact',
  'locataire' = 'locataire',
  'payeur' = 'payeur',
}

export enum EnumFullInterventionsWithoutTimeFactureType {
  'particulier' = 'particulier',
  'professionnel' = 'professionnel',
  'collectivité' = 'collectivité',
  'syndic' = 'syndic',
  'gestionnaire' = 'gestionnaire',
  'contact' = 'contact',
}

export enum EnumFullInvoicesInterventionPaymentType {
  'immediate' = 'immediate',
  'invoice' = 'invoice',
}

export enum EnumFullTobedoneCrmStatus {
  'contacte' = 'contacte',
  'messagerie' = 'messagerie',
  'sans reponse' = 'sans reponse',
  'a contacter' = 'a contacter',
  'refuse' = 'refuse',
  'refus definitif' = 'refus definitif',
}

export enum EnumInterventionReportPaymentType {
  'immediate' = 'immediate',
  'invoice' = 'invoice',
}

export enum EnumInterventionContactRelationType {
  'locataire' = 'locataire',
  'proprietaire' = 'proprietaire',
  'syndic' = 'syndic',
  'marche' = 'marche',
  'contact' = 'contact',
}

export enum EnumInterventionInvoiceRelationType {
  'locataire' = 'locataire',
  'proprietaire' = 'proprietaire',
  'syndic' = 'syndic',
  'marche' = 'marche',
  'contact' = 'contact',
}

export enum EnumInvoiceSortColumn {
  'customer_name' = 'customer_name',
  'invoice_number' = 'invoice_number',
  'date' = 'date',
  'due_date' = 'due_date',
  'total' = 'total',
  'balance' = 'balance',
  'created_time' = 'created_time',
}

export enum EnumInvoiceStatus {
  'sent' = 'sent',
  'draft' = 'draft',
  'overdue' = 'overdue',
  'paid' = 'paid',
  'void' = 'void',
  'unpaid' = 'unpaid',
  'partially_paid' = 'partially_paid',
  'viewed' = 'viewed',
}

export enum EnumProType {
  'syndic' = 'syndic',
  'marche' = 'marche',
}
