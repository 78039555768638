import frLocale from '@fullcalendar/core/locales/fr'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import type { EventReceiveArg } from '@fullcalendar/interaction'

import type {
  EventClickArg,
  EventChangeArg,
  EventAddArg,
  EventInput,
  EventApi,
} from '@fullcalendar/react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { Chip, Tooltip } from '@material-ui/core'
import type {
  FullContract,
  FullIntervention,
  FullSpecialEvent,
  Contact,
} from '@willig/types/api'
import { add, formatISO, format, differenceInMinutes } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { useNotify, useUpdate, useCreate } from 'react-admin'
import { useQueryClient } from 'react-query'
import { concatNames } from 'src/libs/concatNames'
import { useList } from 'src/libs/useGetList'
import type { InterventionCreate } from 'src/types/api/extendedTypes'
import { v4 as uuidv4 } from 'uuid'

import { theme } from '../../UI'

import type { SelectedEvent } from './AgendaBody'
import { ConfirmDeleteModal, DetailModal } from './DetailModal'
import { useStyles } from './useSweepCalendarTheme'

type SweepCalendarProps = {
  sweep_id: string
  name: string
  initialDate: string
}

type FullInterventionWithType = {
  type: 'intervention'
} & FullIntervention

type SpecialEventWithType = {
  type: 'specialEvent'
} & FullSpecialEvent

export type EventPayload = {
  contract: FullContract
}

export const SweepCalendar = (props: SweepCalendarProps) => {
  const { sweep_id, name, initialDate } = props

  const notify = useNotify()
  const [updateInterventionSweep] = useUpdate('Intervention')
  const [updateEvent] = useUpdate('SpecialEvent')
  const [create] = useCreate()
  const [modalState, toggleModal] = useState<boolean>(false)
  const [deleteModalState, toggleDeleteModal] = useState<boolean>(false)
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent>({
    id: undefined,
    type: 'Intervention',
  })
  const queryClient = useQueryClient()
  const { refresh, doOnRefresh } = useInvalidateOnRefresh()

  const { data: allEvents, refetch } = useList(
    'AllEventsForCalendar',
    {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'rdv_date', order: 'desc' },
      filter: { sweep_id: sweep_id },
    },
    { enabled: Boolean(sweep_id) },
  )

  const createMutationOptions = (event: EventApi) => {
    return {
      onFailure: (error: any) => {
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        )
      },
      onSuccess: () => {
        notify("creation de l'element", 'success', undefined, true, 1000)
        doOnRefresh(() => event.remove())
        queryClient.invalidateQueries(['getInterventionsCalendarList'])
        refetch()
        refresh()
      },
    }
  }

  const createInterventionFromContract = (
    info: EventReceiveArg,
    sweepId: string,
  ) => {
    if (info.event._def.sourceId) {
      return
    }

    const date = info.event.start
    if (!date) {
      notify('Erreur du calendrier: Aucune date ou heure à été trouvée')
      return
    }
    const rdv_date = formatISO(date, { representation: 'date' })
    const rdv_time = format(date, 'HH:mm:ss')
    const { contract } = info.event.extendedProps.payload as EventPayload

    const intervention: InterventionCreate = {
      ...contract,
      adresse_id: contract.adresse_id!, // weird
      id: uuidv4(),
      contract_id: contract.id,
      sweep_id: sweepId,
      rdv_date,
      rdv_time,
      duration: 60,
      // @ts-ignore
      invoice_relation_type: contract?.type ?? undefined,
      // @ts-ignore
      contact_relation_type: contract?.contact_type ?? undefined,
    }
    create(
      {
        resource: 'Intervention',
        payload: { data: intervention },
      },
      createMutationOptions(info.event),
    )
  }

  const updateIntervention = (
    info: EventAddArg | EventChangeArg,
    sweepId: string,
  ) => {
    const interventionId =
      info.event._def.publicId || info.event._def.extendedProps.interventionId
    const { start } = info.event
    const { end } = info.event
    if (!end || !start) {
      notify("Erreur lors de l'operation")
      return
    }
    const duration = differenceInMinutes(end, start)
    const rdv_date = formatISO(start, { representation: 'date' })
    const rdv_time = format(start, 'HH:mm:ss')
    updateInterventionSweep(
      {
        payload: {
          id: interventionId,
          data: { sweep_id: sweepId, rdv_date, rdv_time, duration },
        },
      },
      createMutationOptions(info.event),
    )
  }
  const createEvent = (info: EventReceiveArg, sweepId: string) => {
    if (info.event._def.sourceId) {
      return
    }
    const date = info.event.start
    if (!date) {
      notify('Erreur du calendrier: Aucune date ou heure à été trouvée')
      return
    }
    const id = uuidv4()
    const event_type_id = info.event._def.extendedProps.typeId
    const rdv_date = formatISO(date, { representation: 'date' })
    const rdv_time = format(date, 'HH:mm:ss')
    const specialEvent = {
      id,
      rdv_date,
      rdv_time,
      sweep_id: sweepId,
      event_type_id,
      duration: 60,
    }

    create(
      {
        resource: 'SpecialEvent',
        payload: { data: specialEvent },
      },
      createMutationOptions(info.event),
    )
  }
  const updateSpecialEvent = (
    info: EventAddArg | EventChangeArg,
    sweepId: string,
  ) => {
    const specialEventId =
      info.event._def.publicId || info.event._def.extendedProps.eventId
    const { start } = info.event
    const { end } = info.event
    if (!end || !start) {
      notify("Erreur lors de l'operation")
      return
    }
    const duration = differenceInMinutes(end, start)
    const rdv_date = formatISO(start, { representation: 'date' })
    const rdv_time = format(start, 'HH:mm:ss')
    updateEvent(
      {
        payload: {
          id: specialEventId,
          data: { sweep_id: sweepId, rdv_date, rdv_time, duration },
        },
      },
      createMutationOptions(info.event),
    )
  }
  const eventRouter = (
    info: EventChangeArg | EventReceiveArg | EventAddArg,
    action: 'eventChange' | 'eventAdd' | 'eventReceive',
  ) => {
    const { eventType } = info.event._def.extendedProps

    switch (action) {
      case 'eventChange':
        if (eventType === 'intervention')
          updateIntervention(info as EventChangeArg, sweep_id)
        if (eventType === 'specialEvent')
          updateSpecialEvent(info as EventChangeArg, sweep_id)
        break
      case 'eventReceive':
        if (eventType === 'intervention')
          createInterventionFromContract(info as EventReceiveArg, sweep_id)
        if (eventType === 'specialEvent')
          createEvent(info as EventReceiveArg, sweep_id)
        break
      case 'eventAdd':
        if (eventType === 'intervention')
          updateIntervention(info as EventAddArg, sweep_id)
        if (eventType === 'specialEvent')
          updateSpecialEvent(info as EventAddArg, sweep_id)
        break
    }
  }

  const classes = useStyles()

  const calendarRef = useRef<FullCalendar>(null)

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(initialDate)
    }
  }, [initialDate])

  return (
    <div className={classes.root}>
      <div
        style={{ display: 'flex', justifyContent: 'center', padding: '8px' }}
      >
        <Chip label={name} color="primary" />
      </div>
      <FullCalendar
        scrollTimeReset={false}
        editable={true}
        droppable={true}
        ref={calendarRef}
        locales={[frLocale]}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        locale="fr"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialDate={initialDate}
        selectable={false}
        slotMinTime="05:00:00"
        slotMaxTime="21:00:00"
        eventClick={(info: EventClickArg) => {
          const eventId = info.event._def.publicId
          const type = info.event._def.extendedProps.eventType

          setSelectedEvent({
            id: eventId,
            type: type === 'specialEvent' ? 'SpecialEvent' : 'Intervention',
          })
          toggleModal(true)
          return false
        }}
        height={`calc(100vh - 200px)`}
        allDaySlot={false}
        initialView="timeGridDay"
        weekends={false}
        events={allEvents?.data
          .filter((event: any) => typeof event !== 'undefined')
          .map(converter)}
        eventChange={(info: EventChangeArg) => {
          eventRouter(info, 'eventChange')
        }}
        eventReceive={(info: EventReceiveArg) => {
          eventRouter(info, 'eventReceive')
        }}
        eventAdd={(info: EventAddArg) => {
          eventRouter(info, 'eventAdd')
        }}
        displayEventTime={false}
        eventContent={renderEventContent}
      />
      <DetailModal
        toggleModal={toggleModal}
        toggleDeleteModal={toggleDeleteModal}
        modalState={modalState}
        selectedEvent={selectedEvent}
        refetch={refetch}
      />
      <ConfirmDeleteModal
        selectedEvent={selectedEvent}
        toggleDeleteModal={toggleDeleteModal}
        toggleModal={toggleModal}
        deleteModalState={deleteModalState}
        refetch={refetch}
      />
    </div>
  )
}

function renderEventContent(eventInfo: any) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        maxHeight: '100%',
        overflow: 'hidden',
      }}
    >
      <Tooltip
        title={
          <div>
            <h4
              style={{ margin: 0, fontWeight: 'bold' }}
            >{`${eventInfo.event.title}`}</h4>
            <h4 style={{ marginTop: '4px' }}>
              {`${eventInfo.event.extendedProps.infos ?? 'non renseigné'}`}
            </h4>
          </div>
        }
      >
        <div
          style={{
            display: 'flex',
            gap: '4px',
            flexDirection: 'column',
          }}
        >
          <p style={{ margin: 0, fontWeight: 'bold' }}>
            {`${eventInfo.event.title}`}
          </p>
          <p style={{ margin: 0 }}>
            {`${eventInfo.event.extendedProps.infos ?? 'non renseigné'}`}
          </p>
        </div>
      </Tooltip>
    </div>
  )
}

const converter = (event: any): EventInput => {
  const { type } = event
  switch (type) {
    case 'intervention':
      return ConverIntervention(event)
    case 'specialEvent':
      return convertSpecialEvent(event)
    default:
      return {}
  }
}

const ConverIntervention = (intervention: FullInterventionWithType) => {
  const {
    id,
    rdv_date,
    rdv_time,
    fixed_slot,
    int_rue,
    int_ville,
    int_code_postal,
    duration,
    sweep_id,
    type,
    bg_color,
    text_color,
    prerequisites,
    infos,
    contact_nom,
    contact_prenom,
    facture_nom,
    facture_prenom,
    contact_mobile,
    contact_raison_sociale,
    facture_mobile,
    facture_raison_sociale,
    facture_fixe,
    contract_id,
  } = intervention

  const start = new Date(`${rdv_date} ${rdv_time}`)
  const end = add(start, { minutes: duration ?? 30 })
  const slot_color = fixed_slot ? theme.palette.grey[800] : bg_color

  const textColor = fixed_slot
    ? theme.palette.secondary.contrastText
    : text_color

  const contactFullName = concatNames({
    nom: contact_nom,
    prenom: contact_prenom,
    raison_sociale: contact_raison_sociale,
  } as Contact)

  const factureFullName = concatNames({
    nom: facture_nom,
    prenom: facture_prenom,
    raison_sociale: facture_raison_sociale,
  } as Contact)

  const title = [
    `${int_rue} ${int_code_postal} ${int_ville}`,
    contactFullName ?? factureFullName,
  ].join(' - ')

  return {
    id: id,
    title: title,
    start: start,
    end: end,
    backgroundColor: slot_color,
    borderColor: contract_id ? 'black' : slot_color,
    editable: !fixed_slot,
    sweep_id: sweep_id,
    eventType: type,
    textColor: textColor,
    prerequisites,
    infos,
    contact_nom,
    facture_nom,
    contact_mobile,
    facture_mobile,
    facture_fixe,
  }
}

const convertSpecialEvent = (specialEvent: SpecialEventWithType) => {
  const {
    id,
    rdv_date,
    rdv_time,
    duration,
    sweep_id,
    type,
    bg_color,
    text_color,
    label,
    description,
  } = specialEvent
  const start = new Date(`${rdv_date} ${rdv_time}`)
  const end = add(start, { minutes: duration ?? 30 })
  return {
    id: id,
    title: `${description ?? label}`,
    start: start,
    end: end,
    backgroundColor: bg_color,
    borderColor: bg_color,
    editable: true,
    sweep_id: sweep_id,
    eventType: type,
    textColor: text_color,
  }
}

type Callback = () => void
function useInvalidateOnRefresh() {
  const callbacksRef = useRef<Callback[]>([])

  return {
    refresh() {
      callbacksRef.current.forEach((cb) => {
        cb()
      })
    },
    doOnRefresh(cb: Callback) {
      callbacksRef.current.push(cb)
    },
  }
}
