import type { DailyInvoices } from 'src/types/api/extendedTypes'

import { SinaoStatusEnum } from '../../../types/api/extendedTypes'
import type { LocalFilter, ParticularFilter, ProFilters } from '../types'

export function filterDataLocally(
  isPro: boolean,
  data: DailyInvoices[],
  filters: Partial<LocalFilter>,
) {
  if (isPro) return filterProData(data, filters)
  return filterParticularData(data, filters)
}

function filterProData(data: DailyInvoices[], filters: Partial<ProFilters>) {
  const { contractId } = filters
  if (!contractId) return data
  return data.filter((invoice) => invoice?.pro_id === contractId)
}

function filterParticularData(
  data: DailyInvoices[],
  filters: Partial<ParticularFilter>,
) {
  const { ramoneur, type_paiement, sinaoStatus } = filters

  const paiementType = type_paiement === 'impayé' ? null : type_paiement

  let filteredDatas = data

  if (ramoneur) {
    filteredDatas = filteredDatas.filter((inv) => inv.ramoneur === ramoneur)
  }
  if (type_paiement) {
    filteredDatas = filteredDatas.filter(
      (inv) => inv.type_paiement === paiementType,
    )
  }
  if (sinaoStatus) {
    filteredDatas = filteredDatas.filter((inv) => {
      if (sinaoStatus === SinaoStatusEnum.notBilled) {
        return inv.sinao_invoice_status === null
      }
      if (sinaoStatus === SinaoStatusEnum.billed) {
        return inv.sinao_invoice_status !== null
      }
      return inv.sinao_invoice_status === sinaoStatus
    })
  }

  return filteredDatas
}
