/* eslint-disable react/jsx-key */

import React from 'react'
import { Resource } from 'react-admin'

import adresse from './adresse'
import anomalie from './anomalie'
import appareil from './appareil'
import catalog from './catalog'
import combustible from './combustible'
import complexite from './complexite'
import contact from './contact'
import emplacement from './emplacement'
import ExtranetUsers from './extranetUsers'
import hauteur from './hauteur'
import interventions from './intervention'
import marque from './marque'
import materiauConduit from './materiauConduit'
import pro from './pro'
import produit from './produit'
import ramoneDepuis from './ramoneDepuis'
import secteur from './secteur'
import section from './section'
import typeClient from './typeClient'
import TypeEncaissement from './typeEncaissement'
import typeInstallation from './typeInstallation'
import typeTva from './typeTva'
import user from './user'
import zone from './zone'

export const resources = () => {
  return [
    <Resource name="Intervention" {...interventions} />,
    <Resource name="Pro" {...pro} />,
    <Resource name="StatusIntervention" />,
    <Resource name="File" />,
    <Resource name="AllEventsForCalendar" />,
    <Resource name="FullSpecialEvent" />,
    <Resource name="FullIntervention" />,
    <Resource name="FullInterventionsWithoutTime" />,
    <Resource name="FullCatalogOnlyPrice" {...catalog} />,
    <Resource name="Price" />,
    <Resource name="Adress" {...adresse} />,
    <Resource name="FullAddress" {...adresse} />,
    <Resource name="Contact" {...contact} />,
    <Resource name="Appareil" {...appareil} />,
    <Resource name="Anomalie" {...anomalie} />,
    <Resource name="Combustible" {...combustible} />,
    <Resource name="Complexite" {...complexite} />,
    <Resource name="Emplacement" {...emplacement} />,
    <Resource name="Hauteur" {...hauteur} />,
    <Resource name="Marque" {...marque} />,
    <Resource name="MateriauConduit" {...materiauConduit} />,
    <Resource name="Produit" {...produit} />,
    <Resource name="RamoneDepui" {...ramoneDepuis} />,
    <Resource name="Secteur" {...secteur} />,
    <Resource name="Section" {...section} />,
    <Resource name="TypeClient" {...typeClient} />,
    <Resource name="TypeEncaissement" {...TypeEncaissement} />,
    <Resource name="TypeInstallation" {...typeInstallation} />,
    <Resource name="TypeTva" {...typeTva} />,
    <Resource name="User" {...user} />,
    <Resource name="UserExtranet" {...ExtranetUsers} />,
    <Resource name="Zone" {...zone} />,
  ].sort((a, b) => a.props.name.localeCompare(b.props.name))
}
