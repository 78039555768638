import type { Pro } from '@willig/types/api'
import { DatePicker, Picker } from 'src/components/Form'
import { useGetList } from 'src/libs/useGetList'

type Props = { onChange: any }

export function ProFilters(props: Props) {
  const { onChange } = props
  const { data: pros } = useGetList<Pro>('Pro', {
    pagination: { page: 0, perPage: 0 },
  })

  return (
    <>
      <Picker
        options={
          pros.map((pro) => {
            return {
              value: pro.id,
              label: pro.name,
            }
          }) ?? []
        }
        name="contractId"
        label="Contrat"
        onChange={() => onChange()()}
      />
      <DatePicker
        name="year"
        label="Année"
        views={['year']}
        onChange={() => onChange()()}
        maxDate={new Date()}
      />
    </>
  )
}
