import { Box, CircularProgress, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import type { Theme } from '@material-ui/core/styles'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { useMemo, useState } from 'react'
import { Confirm } from 'react-admin'

import { useMutation } from 'react-query'

import { useInvoiceQueryBuilder } from 'src/libs/invoiceQueryBuilder'

import type { InvoicesSelectedState } from '../index'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 'full',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    error: {
      display: 'flex',
      flexDirection: 'column',
    },
    legend: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
  }),
)

type Props = {
  invoiceIds: InvoicesSelectedState
  isPro: boolean
  onSuccess: () => void
}

export function SendInvoiceButton(props: Props) {
  const { invoiceIds, onSuccess, isPro } = props
  const { container, error } = useStyles()

  const invoicesMap = Object.keys(invoiceIds).filter(
    (invoiceId) => invoiceIds[invoiceId]?.selected,
  )

  const contactIds = Object.values(invoiceIds)
    .filter((inv) => inv?.selected)
    .map((inv) => inv?.invoice?.infos_contacts?.contact_id)
  const addressIds = Object.values(invoiceIds)
    .filter((inv) => inv?.selected)
    .map((inv) => inv?.invoice?.facturation_adresse?.adresse_id)

  const proIds = Object.values(invoiceIds)
    .filter((inv) => inv?.selected)
    .map((inv) => inv?.invoice?.pro_id)

  const invoiceQueryBuilder = useInvoiceQueryBuilder()
  const { mutateAsync, isLoading } = useMutation(
    invoiceQueryBuilder.sendInvoices(),
  )
  const { mutateAsync: ProMutateAsync, isLoading: isProLoading } = useMutation(
    invoiceQueryBuilder.sendProInvoices(),
  )

  async function sendAllInvoices() {
    if (isPro) {
      const payload = {
        invoiceIds: invoicesMap,
        contactIds: contactIds as string[],
        addressIds: addressIds as string[],
        proIds: proIds as string[],
      }
      ProMutateAsync(payload, { onSuccess })
    } else {
      mutateAsync(invoicesMap, { onSuccess })
    }
  }

  const title = getButtonTitle(invoicesMap.length)

  const isGlobalLoading = Boolean(isLoading || isProLoading)

  const [open, setOpen] = useState(false)

  const hasError = useMemo(() => {
    if (!isPro) {
      return {
        error: false,
        message: '',
      }
    }
    if (isPro && proIds.length && new Set(proIds).size !== 1) {
      return { error: true, message: 'Les id de pro sont différents' }
    }
    if (contactIds.length && new Set(contactIds).size !== 1) {
      return { error: true, message: 'Les contacts ne sont pas identiques' }
    }
    if (addressIds.length && new Set(addressIds).size !== 1) {
      return { error: true, message: 'Les addresses ne sont pas identiques' }
    }
    return {
      error: false,
      message: '',
    }
  }, [addressIds, contactIds, isPro, proIds])

  return (
    <Box className={container}>
      <Legend />
      {isGlobalLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Confirm
            isOpen={open}
            title={`Regrouper les ${invoicesMap.length} factures`}
            content={`Vous vous apprêtez à créer une facture qui regroupe les  ${invoicesMap.length} interventions sélectionnées`}
            onConfirm={() => {
              sendAllInvoices()
              setOpen(false)
            }}
            onClose={() => setOpen(false)}
          />
          <Box className={error}>
            <Button
              variant="contained"
              color="primary"
              disabled={Boolean(
                invoicesMap.length === 0 || isGlobalLoading || hasError.error,
              )}
              onClick={() => {
                if (isPro && invoicesMap.length > 1) {
                  setOpen(true)
                } else {
                  sendAllInvoices()
                }
              }}
            >
              {title}
            </Button>
            {hasError.error && (
              <Typography color="error">{hasError.message}</Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

function getButtonTitle(length: number) {
  if (length === 0) return 'Envoyer en facturation'
  return `Envoyer en facturation (${length})`
}

function Legend() {
  const { legend } = useStyles()
  const theme = useTheme()
  return (
    <Box className={legend}>
      <Box
        style={{
          backgroundColor: theme.palette.grey[500],
          padding: '4px 16px',
          borderRadius: '4px',
          color: 'white',
        }}
      >
        Brouillon
      </Box>
      <Box
        style={{
          backgroundColor: theme.palette.warning.main,
          padding: '4px 16px',
          borderRadius: '4px',
          color: 'white',
        }}
      >
        Facturé
      </Box>
      <Box
        style={{
          backgroundColor: theme.palette.primary.light,
          padding: '4px 16px',
          borderRadius: '4px',
          color: 'white',
        }}
      >
        Payé
      </Box>
    </Box>
  )
}
