import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import { Stack } from '../../../components/UI/Stack'

type TotalFooterProps = {
  total: number
  totalPage: number
}

export function TotalFooter(props: TotalFooterProps) {
  const { total, totalPage } = props

  const theme = useTheme()

  if (total === 0) return null
  return (
    <Stack
      css={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(4),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.light,
        color: 'white',
      }}
    >
      <>
        <Typography variant={'h5'}>Total (TTC)</Typography>
        <Typography variant={'h5'}>
          {totalPage} € / {total} €
        </Typography>
      </>
    </Stack>
  )
}
