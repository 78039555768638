import React from 'react'
import { Route } from 'react-router-dom'
import { Agenda } from 'src/page/Agenda'

import { Invoices } from 'src/page/Invoice'
import { ToBeDone } from 'src/page/ResteAFaire'

import { FullStats } from './page/FullStat'

import { ThreeCX } from './page/ThreeCX'

export const CustomRoutes = [
  <Route exact key={'agenda'} path="/Agenda" component={Agenda} />,
  <Route exact key={'toBeDone'} path="/ToBeDone" component={ToBeDone} />,
  <Route exact key={'invoices'} path="/invoices" component={Invoices} />,
  <Route
    exact
    key={'invoices/pro'}
    path="/invoices/pro"
    component={() => <Invoices pro />}
  />,
  <Route exact key={'fullStats'} path="/Dashboard" component={FullStats} />,
  <Route exact key={'3cx'} path="/3cx" component={ThreeCX} />,
]
