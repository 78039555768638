import { endOfYear, startOfYear, format, isValid } from 'date-fns'

import type { ApiFilters, LocalFilter } from './types'

export function encodeApiFilters(filters: Partial<LocalFilter>): ApiFilters {
  if (filters.isPro) return encodeProFilters(filters)
  return encodeIndividualFilters(filters)
}

function encodeProFilters(filters: Partial<LocalFilter>): ApiFilters {
  const { year } = filters
  console.log('year', year)
  const date = new Date(String(year))

  return {
    isPro: true,
    startDate: format(startOfYear(date), 'yyyy-MM-dd'),
    endDate: format(endOfYear(date), 'yyyy-MM-dd'),
  }
}

function encodeIndividualFilters(filters: Partial<LocalFilter>): ApiFilters {
  const { startDate, endDate } = filters
  if (!startDate || !endDate) return { isPro: false }
  if (!isValid(new Date(startDate)) || !isValid(new Date(endDate))) {
    return { isPro: false }
  }
  const encodedDates = encodeDates(new Date(startDate), new Date(endDate))

  const { encodedStartDate, encodedEndDate } = encodedDates

  return {
    isPro: false,
    startDate: encodedStartDate,
    endDate: encodedEndDate,
  }
}

function encodeDates(startDate: Date, endDate: Date) {
  return {
    encodedStartDate: format(startDate, 'yyyy-MM-dd 00:00:01'),
    encodedEndDate: format(endDate, 'yyyy-MM-dd 23:59:59'),
  }
}
