import { Box } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import type { PropsWithChildren } from 'react'

type StackProps = {
  css?: Record<string, any>
}

export function Stack(props: PropsWithChildren<StackProps>) {
  const { children, css = {} } = props
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        ...css,
      },
    }),
  )
  const styles = useStyles()
  return <Box className={styles.root}>{children}</Box>
}
