import type { FC } from 'react'
import React from 'react'
import type { ListProps, FilterProps } from 'react-admin'
import {
  BulkExportButton,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin'

import { DefaultPagination } from '../../components/defaultPagination'

export const ListView = (props: ListProps) => {
  return (
    <List
      {...props}
      filters={<AdresseFilters />}
      bulkActionButtons={<BulkExportButton />}
      pagination={<DefaultPagination />}
      perPage={50}
    >
      <Datagrid rowClick={'edit'}>
        <TextField source="rue" />
        <TextField source="code_postal" />
        <TextField source="ville" />
        <TextField source="cote" label="Intitulé d'adresse" />
        <ReferenceField label="Zone" source="zone" reference="Zone">
          <TextField source="label" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}

const AdresseFilters: FC<Partial<FilterProps>> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Rue" source="rue" alwaysOn />
      <TextInput label="Code postal" source="code_postal" alwaysOn />
      <TextInput label="Ville" source="ville" alwaysOn />
    </Filter>
  )
}
