/** https://marmelab.com/react-admin/Caching.html */
import type {
  Contact,
  FullInterventionsWithoutTime,
  FullInvoicesIntervention,
  FullSpecialEvent,
  FullTobedone,
  FullContactAdresse,
  Intervention,
} from '@willig/types/api'
import type { DataProvider, GetOneParams } from 'ra-core'
import type { CreateParams, UpdateParams } from 'react-admin'
import type { InterventionDetail } from 'src/types/api/extendedTypes'
import { v4 as uuidv4 } from 'uuid'

export function antiCorruptionLayerProxy(dataProvider: DataProvider) {
  const proxies = Proxies(dataProvider)
  return new Proxy(dataProvider, {
    get(_, name: any) {
      return function wrappedMethod(resource: string, params: any) {
        // @ts-ignore
        const proxy = proxies[resource]
        if (resource.includes('/UserEextranet')) {
          // eslint-disable-next-line dot-notation
          return proxy['UserExtranet'](params)
        }
        if (resource in proxies) {
          if (name in proxy) {
            // @ts-ignore
            return proxy[name](params)
          }
        }
        return dataProvider[name](resource, params)
      }
    },
  })
}

const Proxies = (dataProvider: DataProvider) => {
  return {
    FullCatalogOnlyPrice: FullCatalogProxy(dataProvider),
    AllEventsForCalendar: AllEventsForCalendarProxy(dataProvider),
    FullTobedone: FullToBeDoneProxy(dataProvider),
    FullInvoicesIntervention: FullInvoicesInterventionProxy(dataProvider),
    UserExtranet: UserExtranetProxy(dataProvider),
    FullZoneCity: FullZoneCityProxy(dataProvider),
    FullAddress: FullAddressProxy(dataProvider),
    Intervention: InterventionProxy(dataProvider),
  }
}

const FullZoneCityProxy = (dataProvider: DataProvider) => {
  return {
    getList: async (params: any) => {
      const results = await dataProvider.getList('FullZoneCity', params)
      const parsedResult = results.data.map((res) => {
        return {
          ...res,
          cities: JSON.parse(res.cities),
          id: res.zone,
        }
      })
      return { data: parsedResult, total: results.total }
    },
  }
}
const FullAddressProxy = (dataProvider: DataProvider) => {
  return {
    getOne: async (params: GetOneParams) => {
      const result = await dataProvider.getOne('FullAddress', {
        id: params.id,
      })
      const anomalies = JSON.parse(result.data.anomalies)
      return { data: { ...result.data, anomalies } }
    },
  }
}
const UserExtranetProxy = (dataProvider: DataProvider) => {
  return {
    getOne: async (params: GetOneParams) => {
      const result = await dataProvider.getOne('UserExtranet', {
        id: params.id,
      })
      const pro_ids = Object.values(JSON.parse(result.data.pro_ids))
      return { data: { ...result.data, pro_ids } }
    },
    getList: async (params: any) => {
      const results = await dataProvider.getList('UserExtranet', params)
      const parsedResult = results.data.map((res) => {
        return {
          ...res,
          pro_ids: Object.values(JSON.parse(res.pro_ids)),
        }
      })
      return { data: parsedResult, total: results.total }
    },
    update: async (params: UpdateParams) => {
      const { pro_ids } = params.data
      const { previousData, id } = params
      return await dataProvider.update('UserExtranet', {
        id,
        data: { ...params.data, pro_ids: JSON.stringify(pro_ids) },
        previousData: { ...previousData },
      })
    },
    create: async (params: CreateParams) => {
      const { pro_ids } = params.data
      return await dataProvider.create('UserExtranet', {
        data: { ...params.data, pro_ids: JSON.stringify(pro_ids) },
      })
    },
  }
}

function InterventionProxy(dataProvider: DataProvider) {
  return {
    create: async (params: CreateParams<Intervention>) => {
      const { facture_contact_id, contact_id } = params.data

      await createContactAddress(dataProvider, params.data)

      if (facture_contact_id && !contact_id) {
        return await dataProvider.create('Intervention', {
          ...params,
          data: {
            ...params.data,
            contact_id: facture_contact_id,
          },
        })
      }
      return await dataProvider.create('Intervention', params)
    },
    update: async (params: UpdateParams<Intervention>) => {
      await createContactAddress(dataProvider, params.data)
      return await dataProvider.update('Intervention', params)
    },
  }
}

async function createContactAddress(
  dataProvider: DataProvider,
  data: CreateParams<Intervention>['data'],
) {
  const { facture_contact_id, adresse_id, invoice_relation_type } = data

  if (invoice_relation_type && facture_contact_id) {
    const { data: relation } = await dataProvider.getList<FullContactAdresse>(
      'FullContactAdresse',
      {
        pagination: {
          page: 1,
          perPage: 10,
        },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          '!deleted': [1],
          adresse_id: [adresse_id],
          contact_id: [facture_contact_id],
          is_payor: [1],
        },
      },
    )

    if (!relation || relation.length === 0) {
      await dataProvider.create('ContactsAdress', {
        data: {
          adresse_id: adresse_id,
          contact_id: facture_contact_id,
          type: invoice_relation_type,
          is_payor: true,
          deleted: false, // important
        },
      })
    }
  }
}

const FullCatalogProxy = (dataProvider: DataProvider) => {
  return {
    create: async (params: CreateParams) => {
      const { prices } = params.data
      const price_id = uuidv4()

      await dataProvider.create('Price', { data: { id: price_id, ...prices } })
      return await dataProvider.create('Catalog', {
        data: { ...params.data, price: price_id },
      })
    },
    update: async (params: UpdateParams) => {
      const { prices, price } = params.data
      const { previousData, id } = params

      await dataProvider.update('Price', {
        id: price,
        data: prices,
        previousData: previousData.price,
      })
      return await dataProvider.update('Catalog', {
        id,
        data: { ...params.data, price: price },
        previousData: { ...previousData, price: price },
      })
    },
  }
}

export type AllEventForCalendar =
  | (FullInterventionsWithoutTime & { type: 'intervention' })
  | (FullSpecialEvent & { type: 'specialEvent' })

const AllEventsForCalendarProxy = (dataProvider: DataProvider) => {
  return {
    ...dataProvider,
    getList: async (params: any) => {
      const allPromise = await Promise.all([
        dataProvider.getList('FullInterventionsWithoutTime', params),
        dataProvider.getList('FullSpecialEvent', params),
      ])
      const [fullInterventionResult, specialEventsResult] = allPromise
      const interventionWithType = fullInterventionResult.data.map((int) => {
        return { ...int, type: 'intervention' }
      })
      const specialEventWithType = specialEventsResult.data.map((event) => {
        return { ...event, type: 'specialEvent' }
      })
      return {
        data: [...interventionWithType, ...specialEventWithType],
        total: fullInterventionResult.total + specialEventsResult.total,
      }
    },
  }
}

export type RdvEnCours = {
  rdv_date: string
  rdv_time: string
  ramoneur: string
  status: string
}

type inteventionJson = {
  rdv_date: string
  invoice: { price_edited?: string; price_computed?: string; tva: string }
  sweep: string
}

export type ParsedFullToBeDone = {
  current_year: inteventionJson[]
  year_minus_one: inteventionJson[]
  year_minus_two: inteventionJson[]
  contacts: Contact[]
  rdv_en_cours: RdvEnCours[]
} & Omit<
  FullTobedone,
  | 'current_year'
  | 'year_minus_one'
  | 'year_minus_two'
  | 'contacts'
  | 'rdv_en_cours'
>

const FullToBeDoneProxy = (dataProvider: DataProvider) => {
  return {
    getList: async (params: any) => {
      const result = await dataProvider.getList('FullTobedone', params)
      const parsedResult = result.data.map((r) => {
        return {
          ...r,
          current_year: r.current_year ? JSON.parse(r.current_year) : null,
          year_minus_one: r.year_minus_one
            ? JSON.parse(r.year_minus_one)
            : null,
          year_minus_two: r.year_minus_two
            ? JSON.parse(r.year_minus_two)
            : null,
          contacts: r.contacts ? JSON.parse(r.contacts) : null,
          rdv_en_cours: r.rdv_en_cours ? JSON.parse(r.rdv_en_cours) : null,
        }
      })
      return {
        data: [...parsedResult],
        total: result.total,
      }
    },
  }
}

export type ParsedFullInvoicesIntervention = Omit<
  FullInvoicesIntervention,
  'invoice' | 'devis' | 'devis_complementaire' | 'payment' | 'etat_des_lieux'
> & {
  invoice: {
    price_computed: number
    price_edited: number
    tva: number
    pdfUUID: string[]
  }
  devis: {
    divers: []
    pdfUUID: string[]
    price_edited: number
    installations: {}
  }
  devis_complementaire: {
    pdfUUID: string[]
  }
  payment: {
    fileUUID: string[]
  }
  etat_des_lieux: {
    pdfUUID: string[]
  }
}

const FullInvoicesInterventionProxy = (dataProvider: DataProvider) => {
  return {
    getList: async (params: any) => {
      const values = await dataProvider.getList<FullInvoicesIntervention>(
        'FullInvoicesIntervention',
        params,
      )
      const parsedResult = values.data.map(parseInterventionDetail)

      return {
        data: parsedResult,
        total: values.total,
      }
    },
  }
}

export function parseInterventionDetail(
  details: Record<string, any>,
): InterventionDetail {
  return {
    ...details,
    invoice: details.invoice ? JSON.parse(details.invoice) : null,
    devis: details.devis ? JSON.parse(details.devis) : null,
    devis_complementaire: details.devis_complementaire
      ? JSON.parse(details.devis_complementaire)
      : null,
    payment: details.payment ? JSON.parse(details.payment) : null,
    etat_des_lieux: details.etat_des_lieux
      ? JSON.parse(details.etat_des_lieux)
      : null,
  } as InterventionDetail
}
